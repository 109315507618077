import React from 'react'
import { Link } from 'gatsby'
import Img from "gatsby-image"
import { graphql } from "gatsby"

import Layout from '../components/layout'

const IndexPage = () => (
  <>

  </>
)

export default IndexPage
